"use client";

import React, { useEffect, useId, useState } from "react";
import { Builder } from "@builder.io/react";
import clsx from "clsx";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { getSearchSpringClient } from "@/lib/searchspring/client";
import useStoreKeys from "@/hooks/useStoreKeys";
import { ProductData } from "@/lib/5874/types";
import useCurrentPath from "@/hooks/useCurrentPath";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { ProductContextProvider } from "@/providers/ProductContextProvider";
import CommonProductDetails from "../ProductCard/CommonProductDetails/CommonProductDetails";
import Pricing from "../ProductCard/Pricing/Pricing";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";
import Button from "../UI/Button/Button";
import IconOnlyButton from "../UI/Button/IconOnlyButton/IconOnlyButton";
import ProductSliderSkeleton from "../UI/Skeleton/ProductSliderSkeleton";
import PlaceholderImage from "@/app/assets/images/img/placeholder.png";
import PlusIcon from "@/app/assets/images/svg/plus.svg";
import styles from "./ProductCarousel.module.scss";

interface Props {
	title: string;
	profileTag: string;
	type?: "full" | "mini";
}

const ProductCarousel: React.FC<Props> = ({
	title,
	profileTag,
	type = "full",
}) => {
	const { store, lang } = useStoreKeys();

	const [products, setProducts] = useState<Array<ProductData> | undefined>();
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

	useEffect(() => {
		setShowLoadingSpinner(true);
		const getRecommends = async () => {
			const client = await getSearchSpringClient(store, lang);

			const res = await client.enrichedRecommend({
				siteId: client.siteId,
				tag: profileTag,
				limits: 16,
			});

			setProducts(res.results);
			setShowLoadingSpinner(false);
		};

		if (profileTag) {
			getRecommends();
		} else {
			setProducts(undefined);
		}
	}, [profileTag]);

	switch (type) {
		case "mini":
			return <MiniCarousel title={title} products={products} />;
		default:
			return (
				<FullCarousel
					title={title}
					products={products}
					showLoadingSpinner={showLoadingSpinner}
				/>
			);
	}
};

interface CarouselProps {
	title: string;
	products: Array<ProductData> | undefined;
	showLoadingSpinner?: boolean;
}

const FullCarousel = ({
	title,
	products,
	showLoadingSpinner,
}: CarouselProps) => {
	const { store, lang } = useStoreKeys();
	const { session } = useSessionContext();
	const carouselId = useId();
	const currentPath = useCurrentPath();

	const isCustomer = session !== null;

	return (
		<section className={clsx([styles.section])}>
			<h2 className={styles.title}>{title}</h2>
			<Swiper
				modules={[Scrollbar, Navigation, A11y]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
				}}
				slidesPerView={"auto"}
				spaceBetween={16}
			>
				{showLoadingSpinner
					? ProductSliderSkeleton
					: products?.map((product) => {
							return (
								<SwiperSlide key={product.id} className={styles.slide}>
									<ProductContextProvider product={product}>
										<article key={product.id} className={clsx([styles.card])}>
											<div className={styles.inner}>
												<CommonProductDetails
													isCustomer={false}
													clampProductName={true}
												/>

												{!isCustomer ? (
													<Link
														href={`/${store}/${lang}/login?redirect=${currentPath}`}
														className={styles.login}
													>
														Log in for pricing
													</Link>
												) : (
													<Pricing />
												)}
											</div>
										</article>
									</ProductContextProvider>
								</SwiperSlide>
							);
					  })}

				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

const MiniCarousel = ({ title, products }: CarouselProps) => {
	const { store, lang } = useStoreKeys();
	const { session } = useSessionContext();
	const carouselId = useId();
	const currentPath = useCurrentPath();

	const isCustomer = true; //session !== null;

	return (
		<section className={styles.mini}>
			{title && <h2 className={styles.title}>{title}</h2>}
			<Swiper
				modules={[Scrollbar, Navigation, A11y]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
				}}
				slidesPerView={"auto"}
				spaceBetween={16}
			>
				{products?.map((product) => {
					const thumbnail =
						product.media.images?.find((image) => image.isThumbnail) ||
						product.media.images?.[0];

					return (
						<SwiperSlide key={product.id} className={styles.slide}>
							<ProductContextProvider product={product}>
								<article key={product.id} className={clsx([styles.card])}>
									<div className={styles.inner}>
										<img
											src={
												`${thumbnail?.url?.thumbnail}?format=webp` ||
												PlaceholderImage.src
											}
											alt={`Product ${product.name}`}
										/>
										<div className={styles.details}>
											{product.brand && (
												<Link
													className={styles.brand}
													href={`/${store}/${lang}${product.brand.path}`}
												>
													{product.brand.path}
												</Link>
											)}
											<Link
												href={`/${store}/${lang}${product.path}`}
												className={styles.name}
											>
												{product.name}
											</Link>
											{!isCustomer ? (
												<Link
													href={`/${store}/${lang}/login?redirect=${currentPath}`}
													className={styles.login}
												>
													Log in for pricing
												</Link>
											) : (
												<Pricing className={styles.price} />
											)}
										</div>
										<div className={styles.ctaDesktop}>
											<IconOnlyButton
												variant='primary'
												icon={<PlusIcon />}
												type='submit'
											/>
										</div>
									</div>
									<div className={styles.ctaMobile}>
										<Button as='button' variant='primary'>
											Add to cart
										</Button>
									</div>
								</article>
							</ProductContextProvider>
						</SwiperSlide>
					);
				})}

				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

export default ProductCarousel;

Builder.registerComponent(ProductCarousel, {
	name: "SearchSpring Recommendations Carousel",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "longText",
			defaultValue: "Title",
		},
		{
			name: "profileTag",
			friendlyName: "SearchSpring Profile Tag",
			type: "longText",
			defaultValue: "also-viewed",
		},
	],
});
